// import React from 'react';
// import whyDidYouRender from '@welldone-software/why-did-you-render';
import { withContextProviders } from '@supersonic/components';
import config from './config';
import './src/global.css';

export const wrapRootElement = ({ element }) =>
  withContextProviders({ element, config });

// Uncomment the following code to enable whyDidYouRender in development - it's useful for debugging React performance issues
// https://github.com/welldone-software/why-did-you-render
// export const onClientEntry = () => {
//   if (process.env.NODE_ENV !== 'production') {
//     whyDidYouRender(React, {
//       trackAllPureComponents: true,
//     });
//   }
// };

// We override loadPage & loadPagesync to fix canonical redirects
// we also override hovering to disable hover prefetch
export const onClientEntry = () => {
  //@ts-ignore gatsby hack
  const loader = window.___loader;
  const variantPagePathRegex = /\/variant-[0-9a-fA-F-]{36}/;
  const isVariantPage =
    //@ts-ignore gatsby hack
    window.pagePath && variantPagePathRegex.test(window.pagePath);
  if (process.env.NODE_ENV === 'development' || !loader || !isVariantPage) {
    return;
  }
  //@ts-ignore gatsby hack
  const originalPagePath = window.pagePath;
  //@ts-ignore gatsby hack
  window.pagePath = originalPagePath.replace(variantPagePathRegex, '') || '/';

  const originalLoadPageSync = loader.loadPageSync;
  const originalLoadPage = loader.loadPage;

  loader.loadPage = () => {
    const resources = originalLoadPage(originalPagePath);
    if (resources.page) {
      resources.page.matchPath = '*';
    }
    return resources;
  };
  loader.loadPageSync = () => {
    const resources = originalLoadPageSync(originalPagePath);
    if (resources.page) {
      resources.page.matchPath = '*';
    }
    return resources;
  };
};

// we also need to disable prefetching as we don't know the exact page-data path.
// TODO look at prefetch a whole html page on hover?
export const disableCorePrefetching = () => true;
