import { SecondaryNavTheme } from '@supersonic/config';

export const secondaryNavTheme: SecondaryNavTheme = {
  backgroundColor: 'secondary_darker1',
  outlineColor: 'white',
  sectionBackgroundColor: 'secondary_darker2',
  sectionTextHoverColor: 'secondary_lighter2',
  sectionTitleColor: 'secondary_lighter3',
  dividerColor: 'secondary',
  mobileNavButton: {
    borderColor: 'secondary',
    activeBackgroundColor: 'secondary_darker2',
  },
};
