import { StateCreator } from 'zustand';
import equal from 'fast-deep-equal';
import { Slices } from '../types';
import {
  DestinationFinderConfig,
  DestinationFinderSlice,
  DestinationFinderItem,
  FilterParams,
} from './types';
import { fetchJson } from '../utils/fetchJson';
import { getDepartureDateFromQueryParamsDateString } from '../searchResults/utils';
import { getDestinationFinderQueryParams, sanitizeFilterParams } from './utils';
import {
  DEFAULT_QUERY_PARAMS,
  DESTINATION_FINDER_QUESTIONS,
} from './constants';

export const createDestinationFinderSlice =
  (
    config: DestinationFinderConfig,
  ): StateCreator<Slices, [], [], DestinationFinderSlice> =>
  (set, get) => ({
    config,
    isLoading: true,
    items: [],
    searchParams: null,
    filterParams: null,
    questions: DESTINATION_FINDER_QUESTIONS,
    resultsSimilar: false,
    loadDestinationFinder: async () => {
      const searchParams = get().destinationFinder.searchParams;
      const filterParams = get().destinationFinder.filterParams;
      if (!searchParams || !filterParams) return;

      set((state) => ({
        ...state,
        destinationFinder: {
          ...state.destinationFinder,
          isLoading: true,
        },
      }));

      try {
        const queryParams = getDestinationFinderQueryParams(
          searchParams,
          filterParams,
        );
        const response = await fetchJson<{
          results: DestinationFinderItem[];
          results_similar: boolean;
        }>(
          `${
            config.destinationFinderApi
          }/destinations?${queryParams.toString()}`,
        );
        set((state) => ({
          ...state,
          destinationFinder: {
            ...state.destinationFinder,
            items: response.results,
            isLoading: false,
            resultsSimilar: response.results_similar,
          },
        }));
      } catch (e) {
        console.error(e);
      }
    },

    setFilterParamsFromUrl: () => {
      if (typeof window === 'undefined') return;

      const queryParams = new URLSearchParams(window.location.search);
      const filterParams: FilterParams = {};
      ['temperature', 'maxFlightTime', 'budget'].forEach((param) => {
        if (queryParams.has(param)) {
          // @ts-ignore - we know that the param is in the list
          filterParams[param] = queryParams.get(param) as string;
        }
      });
      if (queryParams.has('categories')) {
        filterParams['categories'] = (
          queryParams.get('categories') as string
        ).split(',');
      }
      set((state) => ({
        ...state,
        destinationFinder: {
          ...state.destinationFinder,
          filterParams: sanitizeFilterParams(filterParams),
        },
      }));
    },

    setSearchParamsFromUrl: () => {
      if (typeof window === 'undefined') return;

      const queryParams = new URLSearchParams(window.location.search);
      const airportsParam = (
        queryParams.get('airports') || DEFAULT_QUERY_PARAMS.departureAirports
      ).replace(/ /g, '+');
      const departureDate = (
        queryParams.get('date') || DEFAULT_QUERY_PARAMS.departureDate
      ).replace(/ /g, '+');
      const durationParam =
        queryParams.get('duration') || DEFAULT_QUERY_PARAMS.duration;
      const passengerParam = (
        queryParams.get('passengers') || DEFAULT_QUERY_PARAMS.passengers
      ).replace(/ /g, '+');
      const passengersSplit = passengerParam.split('+');
      const countryParam =
        queryParams.get('country') || DEFAULT_QUERY_PARAMS.country;
      const searchParams = {
        duration: durationParam,
        departureDate: getDepartureDateFromQueryParamsDateString(departureDate),
        departureAirports: airportsParam.split('+').join(),
        adults: passengersSplit[0],
        childAges: passengersSplit.splice(1).join(),
        destinationCountry: countryParam,
      };

      set((state) => ({
        ...state,
        destinationFinder: {
          ...state.destinationFinder,
          searchParams,
        },
      }));
    },

    updateFilterParamsAndReloadDestinationFinder: async (filterParams) => {
      const sanitizedFilterParams = sanitizeFilterParams(filterParams);

      if (equal(sanitizedFilterParams, get().destinationFinder.filterParams)) {
        return;
      }

      set((state) => ({
        ...state,
        destinationFinder: {
          ...state.destinationFinder,
          filterParams: sanitizedFilterParams,
        },
      }));

      const queryParams = new URLSearchParams(window.location.search);

      const filterParamKeys: (keyof FilterParams)[] = [
        'temperature',
        'maxFlightTime',
        'budget',
        'categories',
      ];

      for (const param of filterParamKeys) {
        if (sanitizedFilterParams[param]) {
          if (param === 'categories') {
            const sanitizedParam = sanitizedFilterParams[param] || [];
            queryParams.set(param, sanitizedParam.join(','));
          } else {
            queryParams.set(param, sanitizedFilterParams[param] || '');
          }
        } else {
          queryParams.delete(param);
        }
      }
      const newSearch = queryParams.toString();
      const newSearchWithPrefix = newSearch ? `?${newSearch}` : '';
      const newPath = `${window.location.pathname}${newSearchWithPrefix}${window.location.hash}`;
      window.history.replaceState({}, '', newPath);

      await get().destinationFinder.loadDestinationFinder();
    },

    getIsAnswerSelected: (
      questionKey,
      answerKey,
      selectDefaultIfFilterParamIsNotSet,
    ) => {
      const filterParams = get().destinationFinder.filterParams;
      if (!filterParams) return false;

      const value = filterParams[questionKey];
      if (Array.isArray(value)) {
        return value.includes(answerKey);
      } else if (
        !value &&
        selectDefaultIfFilterParamIsNotSet &&
        answerKey === '0'
      ) {
        return true;
      } else {
        return value === answerKey;
      }
    },

    getFilterParamsWithUpdatedAnswer: (
      questionKey,
      answerKey,
      removeIfSelected,
    ) => {
      const filterParams = get().destinationFinder.filterParams;
      if (!filterParams) return {};

      const currentValue =
        filterParams[questionKey] ||
        (questionKey === 'categories' ? [] : undefined);
      if (Array.isArray(currentValue)) {
        const updatedValue = currentValue.includes(answerKey)
          ? currentValue.filter((value) => value !== answerKey)
          : [...currentValue, answerKey];
        return sanitizeFilterParams({
          ...filterParams,
          [questionKey]: updatedValue,
        });
      } else if (currentValue === answerKey && removeIfSelected) {
        const newFilterParams = { ...filterParams };
        delete newFilterParams[questionKey];
        return sanitizeFilterParams(newFilterParams);
      } else {
        return sanitizeFilterParams({
          ...filterParams,
          [questionKey]: answerKey,
        });
      }
    },

    getNumberOfAppliedFilters: () => {
      const filterParams = get().destinationFinder.filterParams;
      if (!filterParams) return 0;

      return (Object.keys(filterParams) as Array<keyof FilterParams>).reduce(
        (numberOfAppliedFilters, key) => {
          const value = filterParams[key];
          if (Array.isArray(value)) {
            return numberOfAppliedFilters + value.length;
          } else {
            return numberOfAppliedFilters + 1;
          }
        },
        0,
      );
    },

    getDestinationFinderPageUrl: (searchParams) => {
      const config = get().destinationFinder.config;
      const airportsString = searchParams.departureAirports
        .split(',')
        .join('+');
      const dateString = searchParams.departureDate;
      const durationString = searchParams.duration;
      const passengersString = [
        searchParams.adults,
        ...searchParams.childAges.split(','),
      ]
        .filter((value) => !!value)
        .join('+');

      const queryParams = new URLSearchParams({
        airports: airportsString,
        date: dateString,
        duration: durationString,
        passengers: passengersString,
      });
      if (searchParams.destinationCountry)
        queryParams.set('country', searchParams.destinationCountry);

      return `${config.destinationFinderPageUrlStem}?${queryParams.toString()}`;
    },
  });
