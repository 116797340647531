export const getCookie = (cookieName: string): string | null => {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(cookieName + '=')) {
      return cookie.substring(cookieName.length + 1);
    }
  }

  return null;
};

export const setSessionCookie = (key: string, value: string) => {
  document.cookie = `${key}=${value};path=/`;
};

export const setCookie = (key: string, value: string, expireDate?: Date) => {
  const ONE_YEAR_IN_MS = 31556952000;
  const expireString =
    expireDate || new Date(Date.now() + ONE_YEAR_IN_MS).toUTCString();

  document.cookie = `${key}=${value};path=/;expires=${expireString}`;
};

export const removeCookie = (key: string) => {
  if (getCookie(key)) {
    document.cookie = `${key}=;path=/;Max-Age=-99999999;`;
  }
};

export const getQueryParamsWithSessionIdAndPageRequestId =
  (): URLSearchParams => {
    const pageRequestId = getCookie('page-request-id');
    const sessionId = getCookie('session-id');
    const queryParams = new URLSearchParams();
    if (pageRequestId) {
      queryParams.set('pageRequestId', pageRequestId);
    } else if (process.env.NODE_ENV !== 'development') {
      console.warn('page-request-id not found in cookie 🍪');
    }
    if (sessionId) {
      queryParams.set('sessionId', sessionId);
    } else if (process.env.NODE_ENV !== 'development') {
      console.warn('session-id not found in cookie 🍪');
    }
    return queryParams;
  };
