import { LineItemTheme } from '@supersonic/config';

export const lineItemTheme: LineItemTheme = {
  primaryColor: 'primary_darker1',
  light: {
    bgHoverColor: 'primary_lighter6',
  },
  dark: {
    bgColor: 'primary_darker2',
    bgHoverColor: 'primary_darker1',
  },
  invert: {
    bgColor: 'primary',
  },
};
