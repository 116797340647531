import { SearchAlternatives, SearchParams } from './types';
import { addDays } from '../utils/addDays';

export const updateSearchParamsWithSearchAlternatives = (
  alternatives: SearchAlternatives,
  searchParams: SearchParams,
): SearchParams => {
  if ('alternativeDates' in alternatives) {
    return {
      ...searchParams,
      departureDate: findClosestDate(
        alternatives.alternativeDates,
        alternatives.originalDate,
      ),
    };
  } else {
    return {
      ...searchParams,
      departureAirports: alternatives.alternativeAirports.join(','),
    };
  }
};

export const updateSearchParamsWithOriginalValuesFromSearchAlternatives = (
  alternatives: SearchAlternatives,
  searchParams: SearchParams,
): SearchParams => {
  if ('alternativeDates' in alternatives) {
    return {
      ...searchParams,
      departureDate: alternatives.originalDate,
    };
  } else {
    return {
      ...searchParams,
      departureAirports: alternatives.originalAirports.join(','),
    };
  }
};

export const findClosestDate = (
  dates: string[],
  targetDate: string,
): string => {
  const targetDateMs = Date.parse(targetDate);
  const datesDiffMs = dates.map((date) =>
    Math.abs(Date.parse(date) - targetDateMs),
  );
  const smallestDiff = Math.min(...datesDiffMs);
  const indexOfDateWithSmallestDiff = datesDiffMs.indexOf(smallestDiff);
  return dates[indexOfDateWithSmallestDiff];
};

export const getDepartureDateFromQueryParamsDateString = (
  dateString: string,
): string => {
  const isPlusDate = dateString.startsWith('+');
  if (isPlusDate) {
    const daysToAdd = parseInt(dateString.slice(1), 10);
    return addDays(new Date(), daysToAdd).toISOString().split('T')[0];
  } else {
    return dateString;
  }
};
