import { ConfigCruiseGadget } from '@supersonic/config';

const cruiseGadget: ConfigCruiseGadget = {
  desktopLayout: 'vertical',
  mobileCompressed: true,
  cruiseFiltersApi: process.env.CRUISE_FILTERS_API_URL as string,
  cruiseRedirectApi: process.env.CRUISE_REDIRECT_API_URL as string,
};

export default cruiseGadget;
