import type { UtmParamConfigItem } from './types';

export const CONFIG: UtmParamConfigItem[] = [
  {
    name: 'medium',
    cookieName: 'icelolly-utm-medium',
    queryParamName: 'utm_medium',
    adalCookieName: 'me',
  },
  {
    name: 'source',
    cookieName: 'icelolly-utm-source',
    queryParamName: 'utm_source',
    adalCookieName: 'so',
  },
  {
    name: 'campaign',
    cookieName: 'icelolly-utm-campaign',
    queryParamName: 'utm_campaign',
    adalCookieName: 'ca',
  },
  {
    name: 'content',
    cookieName: 'icelolly-utm-content',
    queryParamName: 'utm_content',
    adalCookieName: 'co',
  },
];

export const ADAL_COOKIE_NAME = '__adal_ca';
export const ADAL_COPY_COOKIE_NAME = 'icelolly-adal-ca-copy';

export const UTM_COOKIE_DEFAULT_VALUE = 'unknown';
