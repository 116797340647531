import { ConfigStickyCta } from '@supersonic/config';

const starRating: ConfigStickyCta = {
  backgroundColor: 'sand',
  backgroundColorHover: 'sand_darker1',
  textColor: 'white',
  borderRadiusLevel: '_5',
  links: {
    holidays: {
      icon: 'Sunrise',
      name: 'Holidays',
      url: '/holidays',
    },
    flights: {
      icon: 'Airplane',
      name: 'Flights',
      url: '/flights',
    },
    carHire: {
      icon: 'Car',
      name: 'Car Hire',
      url: '/car-hire',
    },
  },
};
export default starRating;
