import { ConfigFooter } from '@supersonic/config';

const footer: ConfigFooter = {
  copyright:
    'Icelolly Marketing Ltd | Registered in England Company No. 05655962 | VAT No. GB175996442',
  logos: ['icelolly'],
  socialLinks: ['instagram', 'twitter', 'tiktok', 'facebook'],
  theme: {
    backgroundColor: 'sand',
    borderColor: 'grey2',
    linkColor: 'primary_darker1',
    linkHoverColor: 'primary_darker1',
    logosColor: 'primary',
    textColor: 'primary_darker1',
    socialMediaIconColor: 'primary_darker1',
  },
  secondaryLinks: [
    {
      link: '/sitemap',
      text: 'Site map',
    },
    {
      link: '/privacy-policy#11',
      text: 'Cookies',
    },
    {
      link: '',
      text: 'Manage preferences',
      type: 'cookie-preferences-button',
    },
    {
      link: '/privacy-policy',
      text: 'Privacy',
    },
    {
      link: '/terms-and-conditions',
      text: 'Terms and conditions',
    },
  ],
  columns: [
    {
      links: [
        {
          link: '/about-us',
          text: 'About Us',
        },
        {
          link: 'https://careers.icetravelgroup.com',
          text: 'Work with us',
        },
        {
          link: '/blog',
          text: 'Blog',
        },
        {
          link: '/influencer-hub',
          text: 'Influencer Hub',
        },
      ],
    },
    {
      links: [
        {
          link: '/faqs',
          text: 'FAQs',
        },
        {
          link: '/contact-us',
          text: 'Contact us',
        },
        {
          link: '/affiliates',
          text: 'Become an Affiliate',
        },
      ],
    },
    {
      links: [
        {
          link: '/destinations',
          text: 'Destination Guides',
        },
        {
          link: '/featured',
          text: 'Featured Destinations',
        },
        {
          link: '/holidays',
          text: 'Holiday Types',
        },
      ],
    },
  ],
};
export default footer;
