import { createStore as createZustandStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { CreateStoreOptions, Slices } from './types';
import { createSearchResultsSlice } from './searchResults';
import { createHolidayDestinationsSlice } from './holidayDestinations';
import { createCarHireDestinationsSlice } from './carHireDestinations';
import { createAdvertisingSlice } from './advertising';
import { createGptAdsSlice } from './gptAds';
import { createUtmParamsSlice } from './utmParams';
import { createEmailSubscriptionSlice } from './emailSubscription';
import { createBlogSlice } from './blog';
import { createDestinationFinderSlice } from './destinationFinder';

export const createStore = ({
  searchResultsConfig,
  advertisingConfig,
  gptAdsConfig,
  holidayDestinationsConfig,
  carHireDestinationsConfig,
  emailSubscriptionConfig,
  utmParamsConfig,
  blogConfig,
  destinationFinderConfig,
}: CreateStoreOptions) => {
  return createZustandStore<Slices>()(
    devtools((...params) => ({
      searchResults: createSearchResultsSlice(searchResultsConfig)(...params),
      holidayDestinations: createHolidayDestinationsSlice(
        holidayDestinationsConfig,
      )(...params),
      carHireDestinations: createCarHireDestinationsSlice(
        carHireDestinationsConfig,
      )(...params),
      advertising: createAdvertisingSlice(advertisingConfig)(...params),
      gptAds: createGptAdsSlice(gptAdsConfig)(...params),
      utmParams: createUtmParamsSlice(utmParamsConfig)(...params),
      emailSubscription: createEmailSubscriptionSlice(emailSubscriptionConfig)(
        ...params,
      ),
      blog: createBlogSlice(blogConfig)(...params),
      destinationFinder: createDestinationFinderSlice(destinationFinderConfig)(
        ...params,
      ),
    })),
  );
};
