import { BlogTheme } from '@supersonic/config';

export const blogTheme: BlogTheme = {
  tagColor: 'primary',
  fadeColor: 'grey6',
  focusColor: 'primary',
  imageCaptionColor: 'white',
  imageCaptionBackgroundColor: 'primary_darker2',
  imageCaptionBarColor: 'primary',
};
