export const getWasCmpConsentRestored = (): Promise<boolean> => {
  // @ts-ignore can't find TS types for tcfapi, so leaving link to docs below
  // https://github.com/InteractiveAdvertisingBureau/GDPR-Transparency-and-Consent-Framework/blob/master/TCFv2/IAB%20Tech%20Lab%20-%20CMP%20API%20v2.md#addeventlistener
  const { __tcfapi } = window;
  if (!__tcfapi) return Promise.resolve(false);

  return new Promise((resolve) => {
    // @ts-ignore same as above
    __tcfapi('addEventListener', 2, (tcData, listenerSuccess) => {
      if (listenerSuccess) {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        __tcfapi('removeEventListener', 2, () => {}, tcData.listenerId);
        resolve(tcData.eventStatus === 'tcloaded');
      } else {
        resolve(false);
      }
    });
  });
};
