import { StateCreator } from 'zustand';
import { Slices } from '../types';
import { BlogSlice, BlogConfig } from './types';
export const createBlogSlice =
  (config: BlogConfig): StateCreator<Slices, [], [], BlogSlice> =>
  (set, get) => ({
    config,
    getBlogPostUrl: (slug) => {
      return `${get().blog.config.urlPrefix}/${slug}`;
    },
    getAuthorPageUrl: (slug) => {
      return `${get().blog.config.urlPrefix}/authors/${slug}`;
    },
  });
