import { ConfigButton } from '@supersonic/config';

const button: ConfigButton = {
  iconOnlyBorderRadius: '100%',
  primary: {
    backgroundColor: 'primary',
    hoverColor: 'primary_darker1',
    focusColor: 'primary_lighter3',
    font: 'font-foxtrot',
  },
  secondary: {
    backgroundColor: 'white',
    borderColor: 'primary',
    hoverColor: 'primary_lighter4',
    focusColor: 'primary_lighter2',
    font: 'font-foxtrot',
    textColor: 'primary',
  },
  tertiary: {
    focusBorderColor: 'primary',
    font: 'font-foxtrot',
    hoverColor: 'primary_lighter4',
    textColor: 'primary',
  },
  aux: {
    focusBorderColor: 'primary',
    font: 'font-hotel',
    hoverColor: 'primary_lighter4',
    textColor: 'primary_darker1',
  },
};
export default button;
