import React from 'react';
import { Config } from '@supersonic/config';
import { ConfigContext } from './configProvider';
import { ThemeProvider } from './themeProvider';
import { StoreProviderAndInitializer } from './storeProvider';
import { createStore } from '@supersonic/store';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
const queryClient = new QueryClient();

export const withContextProviders = ({
  element,
  config,
}: {
  element: React.ReactNode;
  config: Config;
}) => {
  const store = createStore({
    searchResultsConfig: {
      brokerApi: config.holidaysResultsPage.brokerApi,
      resultsPageUrlStem: config.holidaysResultsPage.urlStem,
      resultsPerPage: config.holidaysResultsPage.resultsPerPage,
      interstitialUrl: config.holidaysResultsPage.interstitialUrl,
      timezone: config.theme.dates.timezone,
    },
    advertisingConfig: {
      brokerApi: config.holidaysResultsPage.brokerApi,
      advertisingApi: config.holidaysResultsPage.advertisingApi,
      clickTripzApi: config.components.holidayGadget.backTab.clickTripzApi,
      mediaAlphaScriptUrl:
        config.components.carHireGadget?.backTab.mediaAlphaScriptUrl,
      site: config.holidaysResultsPage.site,
      clickTripzBackTab: {
        desktopPlacementId:
          config.components.holidayGadget.backTab.desktopPlacementId,
        mobilePlacementId:
          config.components.holidayGadget.backTab.mobilePlacementId,
        siteId: config.components.holidayGadget.backTab.siteId,
        timestampLocalStorageKey:
          config.components.holidayGadget.backTab.timestampLocalStorageKey,
      },
      mediaAlphaBackTab: config.components.carHireGadget?.backTab
        ? {
            enabled: config.components.carHireGadget.backTab.enabled,
            desktopPlacementId:
              config.components.carHireGadget.backTab.desktopPlacementId,
            mobilePlacementId:
              config.components.carHireGadget.backTab.mobilePlacementId,
            timestampLocalStorageKey:
              config.components.carHireGadget.backTab.timestampLocalStorageKey,
            intervalMs: config.components.carHireGadget.backTab.intervalMs,
          }
        : undefined,
    },
    gptAdsConfig: {
      site: config.theme.advertising.gpt.site,
      networkCode: config.theme.advertising.gpt.networkCode,
      parentAdUnitCode: config.theme.advertising.gpt.parentAdUnitCode,
      headerBiddingEnabled: config.theme.advertising.gpt.headerBiddingEnabled,
      env: config.theme.advertising.gpt.env,
    },
    holidayDestinationsConfig: {
      autocompleteApi:
        config.components.holidayGadget.destinationSelector.autocompleteApi,
    },
    carHireDestinationsConfig: config.components.carHireGadget
      ? {
          autocompleteApi:
            config.components.carHireGadget.destinationSelector.autocompleteApi,
        }
      : null,
    emailSubscriptionConfig: config.emailSubscription
      ? {
          emailSubscriptionApi: config.emailSubscription.emailSubscriptionApi,
          isEmailPopupShownCookieName:
            config.emailSubscription.isEmailPopupShownCookieName,
          isEmailSubscribedCookieName:
            config.emailSubscription.isEmailSubscribedCookieName,
        }
      : null,
    utmParamsConfig: { logic: config.theme.utmParamsLogic },
    blogConfig: {
      urlPrefix: config.blog.urlPrefix,
    },
    destinationFinderConfig: {
      destinationFinderApi: config.destinationFinder.destinationFinderApi,
      destinationFinderPageUrlStem: config.destinationFinder.urlStem,
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigContext.Provider value={config}>
        <StoreProviderAndInitializer store={store}>
          <ThemeProvider theme={config.theme}>{element}</ThemeProvider>
        </StoreProviderAndInitializer>
      </ConfigContext.Provider>
    </QueryClientProvider>
  );
};
