import { Config } from '@supersonic/config';
import { createContext, useContext } from 'react';

export const ConfigContext = createContext<Config | null>(null);

export const useConfig = () => {
  const config = useContext(ConfigContext);
  if (!config) throw new Error('Missing ConfigContext.Provider in the tree');
  return config;
};
